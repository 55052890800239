/**
 * Form Validation utilities
 */

// Return `valid` value as expected by control inputs Smooth-UI
export const isValid = (touched, valid) => {
  if (!touched) return undefined;
  if (valid) return true;
  if (!valid) return false;
};

// Required
export const required = value => (value ? undefined : 'Required');

// Required with custom message
// Usage: field.required && requiredWithMessage(field.requiredMessage || 'Required')
// Check if the field is required, if so issue requiredWithMessage.
// IMPORTANT: Must provide fallback message, otherwise the method won't run
export const requiredWithMessage = message => value =>
  value ? undefined : message;

// Email address - matching the API
export const validEmail = value =>
  /\b[a-zA-Z0-9._%\-+NULL]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,6}\b/.test(value) // eslint-disable-line no-useless-escape
    ? undefined
    : 'Please enter a valid email address';

// Numbers only
export const validNumber = value =>
  isNaN(value) ? 'Must be a number' : undefined;

// Minimum value
// Usage: minValue(20)
export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Must be greater than ${min}`;

// Compose validators
// Usage: composeValidators(required, validNumber, minValue(20))
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
