/**
 * FormCheckbox
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  ControlFeedback,
  FormGroup,
  FormCheck,
  Checkbox,
  FormCheckLabel,
  styled,
  withTheme,
} from '@smooth-ui/core-sc';
import FormHelpText from '../../components/FormHelpText/FormHelpText'; // _platform
import FormHint from '../../components/FormHint/FormHint'; // _platform
import FormPlaceholder from '../../components/FormPlaceholder/FormPlaceholder'; // _platform
import { isValid } from '../../utils/formValidation'; // _platform

const CustomFormCheck = styled(FormCheck)`
  align-items: flex-start;
`;

const CustomCheckbox = styled(Checkbox)`
  /* Reset z-index to prevent showing over dropdowns and select elements */
  justify-content: flex-start;
  min-width: 25px;
  z-index: 0;

  &:hover > .sui-checkbox-content {
    border-color: ${props =>
      props.valid === undefined ? props.theme.primary : undefined};
  }

  input:disabled + .sui-checkbox-content {
    background-color: ${props =>
      props.theme.customCheckboxDisabledBackground || props.theme.gray300};
  }
`;

const Indicator = styled.span`
  ${props =>
    props.theme.settingsApp.formPreferOptionalIndicator
      ? `
        font-weight: normal;
        padding-left: 0.25em;
      `
      : 'color: red;'}
`;

const whichIndicator = (
  isRequired,
  preferOptional,
  optionalText,
  requiredText
) => {
  if (isRequired === undefined) return null;

  if (isRequired && !preferOptional) {
    return requiredText;
  }
  if (!isRequired && preferOptional) {
    return optionalText;
  }
  return null;
};

const StyledCheckbox = ({
  input,
  inline,
  disabled,
  immutable,
  isRequiredIndicator,
  meta,
  helpText,
  label,
  hint,
  useHelpTextAsLabel,
  ...rest
}) => (
  <FormGroup>
    {helpText && !useHelpTextAsLabel && <FormHelpText htmlValue={helpText} />}
    <CustomFormCheck inline={inline}>
      {!immutable && (
        <CustomCheckbox
          {...input}
          {...rest}
          id={input.name}
          control
          disabled={disabled}
          valid={isValid(meta.touched, meta.valid)}
          value={input.value ? input.value.toString() : undefined}
        />
      )}
      <FormCheckLabel
        htmlFor={input.name}
        className={`${immutable ? 'show-col' : ''} ${
          useHelpTextAsLabel ? 'helptext-as-label' : ''
        }`}
      >
        {useHelpTextAsLabel ? (
          <FormHelpText htmlValue={helpText} className="helptext-as-label" />
        ) : (
          label
        )}
        <Indicator isRequiredIndicator={isRequiredIndicator}>
          {whichIndicator(
            isRequiredIndicator,
            rest.theme.settingsApp.formPreferOptionalIndicator,
            rest.theme.settingsApp.formIndicatorOptionalText,
            rest.theme.settingsApp.formIndicatorRequiredText
          )}
        </Indicator>
      </FormCheckLabel>
      {immutable && (
        <FormPlaceholder inline>
          {input.value === 'true' || input.value === true ? ' Yes' : ' No'}
        </FormPlaceholder>
      )}
    </CustomFormCheck>
    {hint ? <FormHint>{hint}</FormHint> : null}
    {meta.touched && !meta.valid ? (
      <ControlFeedback valid={meta.valid}>{meta.error}</ControlFeedback>
    ) : null}
  </FormGroup>
);

StyledCheckbox.propTypes = {
  disabled: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  immutable: PropTypes.bool,
  inline: PropTypes.bool,
  input: PropTypes.object.isRequired,
  isRequiredIndicator: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  meta: PropTypes.object.isRequired,
  useHelpTextAsLabel: PropTypes.bool,
};

StyledCheckbox.defaultProps = {
  disabled: false,
  helpText: undefined,
  hint: '',
  immutable: false,
  inline: false,
  isRequiredIndicator: undefined,
  useHelpTextAsLabel: undefined,
};

export default withTheme(StyledCheckbox);
