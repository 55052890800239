/**
 * FormInput Hint
 *
 * Used to display a hint above the input box
 */

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@smooth-ui/core-sc';

const Hint = props => (
  <div className={`lori-input-hint ${props.className}`}>{props.children}</div>
);

Hint.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Hint.defaultProps = {
  className: '',
};

const StyledHint = styled(Hint)`
  color: ${props => props.theme.customInputHintColor || props.theme.gray600};
  font-size: ${props => props.theme.customInputHintFontSize || '0.875em'};
  margin-bottom: 0.5em;
`;

export default StyledHint;
