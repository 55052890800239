/**
 * FormInput
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ControlFeedback,
  css,
  FormGroup,
  Input,
  styled,
} from '@smooth-ui/core-sc';
import FormHelpText from '../../components/FormHelpText/FormHelpText'; // _platform
import FormHint from '../../components/FormHint/FormHint'; // _platform
import FormLabel from '../../components/FormLabel/FormLabel'; // _platform
import FormPlaceholder from '../../components/FormPlaceholder/FormPlaceholder'; // _platform
import { isValid } from '../../utils/formValidation'; // _platform

// Prevent inputInline from being passed through to DOM
const StyledInput = styled(({ inputInline, ...p }) => <Input {...p} />)`
  ${props =>
    props.inputInline
      ? css`
          display: inline-block;
          width: auto;
        `
      : null}

  :hover {
    border-color: ${props =>
      props.valid === undefined ? props.theme.primary : undefined};
  }
`;

class FormInput extends Component {
  render() {
    const {
      input,
      inputInline,
      immutable,
      meta,
      helpText,
      label,
      hint,
      iHaveSuppliedAnExternalLabel,
      isRequiredIndicator,
      ...rest
    } = this.props;

    if (
      process.env.NODE_ENV === 'development' &&
      !label &&
      !iHaveSuppliedAnExternalLabel
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `Dev: FormInput '${input && input.name}' does not have label specified`
      );
    }

    return (
      <FormGroup>
        {helpText && <FormHelpText htmlValue={helpText} />}
        {label ? (
          <FormLabel
            htmlFor={input.name}
            isRequiredIndicator={isRequiredIndicator}
          >
            {label}
          </FormLabel>
        ) : null}
        {hint ? <FormHint>{hint}</FormHint> : null}
        {immutable && <FormPlaceholder>{input.value}</FormPlaceholder>}
        {!immutable && (
          <StyledInput
            {...input}
            {...rest}
            control
            id={input.name}
            inputInline={inputInline}
            valid={isValid(meta.touched, meta.valid)}
          />
        )}

        {meta.touched && !meta.valid ? (
          <ControlFeedback valid={meta.valid}>{meta.error}</ControlFeedback>
        ) : null}
      </FormGroup>
    );
  }
}

FormInput.propTypes = {
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iHaveSuppliedAnExternalLabel: PropTypes.bool,
  immutable: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputInline: PropTypes.bool,
  isRequiredIndicator: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.object.isRequired,
};

FormInput.defaultProps = {
  helpText: undefined,
  hint: '',
  iHaveSuppliedAnExternalLabel: undefined,
  immutable: false,
  inputInline: false,
  isRequiredIndicator: undefined,
  label: undefined,
};

export default FormInput;
