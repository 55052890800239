import React from 'react';
import PropTypes from 'prop-types';
import MessageBox from '../../components/MessageBox/MessageBox'; // _platform

const StatusMessage = props => {
  if (!props.children) return null;

  return props.variant === 'success' || props.variant === 'error' ? (
    <MessageBox {...props} />
  ) : null;
};

StatusMessage.propTypes = {
  variant: PropTypes.string,
};

StatusMessage.defaultProps = {
  variant: undefined,
};

export default StatusMessage;
