import React from 'react';
import PropTypes from 'prop-types';
import { Label, styled, withTheme } from '@smooth-ui/core-sc';
import 'styled-components/macro'; // For the CSS prop on elements

const Indicator = styled.span`
  ${props =>
    props.theme.settingsApp.formPreferOptionalIndicator
      ? `
        font-weight: normal;
        padding-left: 0.25em;
      `
      : 'color: red;'}
`;

const whichIndicator = (
  isRequired,
  preferOptional,
  optionalText,
  requiredText
) => {
  if (isRequired === undefined) return null;

  if (isRequired && !preferOptional) {
    return requiredText;
  }
  if (!isRequired && preferOptional) {
    return optionalText;
  }
  return null;
};

const CustomLabel = props => {
  return (
    <Label
      htmlFor={props.htmlFor}
      css={`
        :after {
          padding-right: 0.125em;
        }
      `}
    >
      {props.children}:
      <Indicator isRequiredIndicator={props.isRequiredIndicator}>
        {whichIndicator(
          props.isRequiredIndicator,
          props.theme.settingsApp.formPreferOptionalIndicator,
          props.theme.settingsApp.formIndicatorOptionalText,
          props.theme.settingsApp.formIndicatorRequiredText
        )}
      </Indicator>
    </Label>
  );
};

CustomLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  isRequiredIndicator: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

CustomLabel.defaultProps = {
  isRequiredIndicator: undefined,
};

export default withTheme(CustomLabel);
