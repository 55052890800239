/**
 * LoginForm
 *
 * This component relies on a LoginProvider-like component to supply data and
 * the submit methods via props.
 *
 * Usage:
 *
 * <LoginProvider disableMeta>
 *   <LoginForm formLabels={{ labelUserName: 'Email' }} />
 * </LoginProvider>
 *
 * disableMeta on the LoginProvider, and formLabels on this component are optional props
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import { withTheme } from '@smooth-ui/core-sc';

// Component features
import { Form, Field } from 'react-final-form';
import { required } from '../../utils/formValidation'; // _platform
import Button, { ButtonBlock } from '../../components/Button/Button'; // _platform
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox'; // _platform
import FormInput from '../../components/FormInput/FormInput'; // _platform
import StatusMessage from '../../components/StatusMessage/StatusMessage'; // _platform
import { Link } from 'react-router-dom';

// Default form labels
// These will be _shallow merged_ with props.formLabels (if provided)
// Usage (from parent component):
// <Login formLabels={{ labelUserName: 'Email' }} />
const defaultFormLabels = {
  labelUserName: 'User Name / Email',
  labelPassword: 'Password',
  labelRememberMe: 'Remember Login',
};

const LoginForm = props => {
  const {
    initialValues,
    onSubmitForm,
    status,
    theme: { settingsApp = {} },
  } = props;
  const labels = { ...defaultFormLabels, ...props.formLabels }; // Merge props with default values
  const { labelUserName, labelPassword, labelRememberMe } = labels;
  // We could avoid merging into the labels const, then destructuring it, however leaving as 2 steps for legibility

  return (
    <div className="login-form">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} method="POST">
            <Field
              name="username"
              component={FormInput}
              type="text"
              required
              validate={required}
              label={labelUserName}
              placeholder={labelUserName}
              disabled={submitting}
            />
            {/* TODO: Form validation */}
            <Field
              name="password"
              component={FormInput}
              type="password"
              required
              validate={required}
              label={labelPassword}
              placeholder={labelPassword}
              disabled={submitting}
            />
            {!!settingsApp.loginRememberMeEnabled &&
              !settingsApp.loginUseSessionStorage && (
                <Field
                  name="rememberLogin"
                  component={FormCheckbox}
                  label={labelRememberMe}
                  type="checkbox"
                />
              )}

            <StatusMessage variant={status.status}>
              {status.statusDetails.message ||
                (status.status === 'error' && (
                  <p>
                    Login Failed. Please remember that passwords are case
                    sensitive.
                  </p>
                ))}
            </StatusMessage>

            <ButtonBlock>
              <Button type="submit" disabled={submitting}>
                Log in
              </Button>
            </ButtonBlock>
          </form>
        )}
      />
      {settingsApp.loginPasswordResetShow &&
        settingsApp.passwordResetEnabled &&
        settingsApp.passwordResetPagePath && (
          <div className="reset-password">
            <Link to={settingsApp.passwordResetPagePath}>
              {settingsApp.loginPasswordResetLabel || 'Reset Password'}
            </Link>
          </div>
        )}
    </div>
  );
};
LoginForm.propTypes = {
  formLabels: PropTypes.shape({
    labelUserName: PropTypes.string,
    labelPassword: PropTypes.string,
    labelRememberMe: PropTypes.string,
  }),
  initialValues: PropTypes.object,
  onSubmitForm: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

LoginForm.defaultProps = {
  formLabels: defaultFormLabels,
  initialValues: {},
  theme: { settingsApp: {} },
};

export default withTheme(LoginForm);
