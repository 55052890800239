/**
 * Form Fields Help Text
 *
 * Used to display a help text above the input label
 */

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@smooth-ui/core-sc';
import sanitiseHTML from '_platform/src/utils/sanitiseHTML';

const FormHelpText = props => (
  <StyledFormHelpText className={`lori-input-help-text ${props.className}`}>
    {props.htmlValue && (
      <div
        dangerouslySetInnerHTML={{ __html: sanitiseHTML(props.htmlValue) }}
      />
    )}
    {props.children}
  </StyledFormHelpText>
);

FormHelpText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlValue: PropTypes.string,
};

FormHelpText.defaultProps = {
  children: undefined,
  className: '',
  htmlValue: undefined,
};

const StyledFormHelpText = styled.div`
  &.helptext-as-label {
    display: inline;

    > div {
      display: inline;
    }
  }

  :not(.helptext-as-label) {
    margin-bottom: 0.5em;
    margin-top: 1.5em;
  }
`;

export default FormHelpText;
