/**
 * FormPlaceholder
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@smooth-ui/core-sc';

const FormPlaceholder = ({ children, inline }) => (
  <Box className="form-placeholder" py={1} pl={inline ? 1 : 0}>
    {children}
  </Box>
);

FormPlaceholder.propTypes = {
  children: PropTypes.node,
  inline: PropTypes.bool,
};

FormPlaceholder.defaultProps = {
  children: undefined,
  inline: undefined,
};

export default FormPlaceholder;
